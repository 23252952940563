<template>
    <div class="home">
         <div v-if="!calculateSevenDay(userInfo.endTime)" class="mcn-text-error">
            <p>温馨提示：您的软件将于<b>{{ userInfo.endTime | calculateDaysDifference }}</b>天后到期，为了避免出现无法使用情况，请提前与我们联系续订。</p>
         </div>

        <div class="container-index">  
        <h1>主播管理</h1>
        <div class="announcement">  
          <h2>汇聚万名主播直播数据，运用百万智能算法精准选品。
</h2>  
          <p style="margin-top:20px"><strong>起号须知</strong></p>  
          <ul>  
            <li>1、新号入驻淘宝主播后，尽快开播，必须每天一场直播，每场4小时以上，连续播满14天，建议每天早晨开播，越早越好。</li>  
            <li>2、14天期间不得挂播、不得漏播，不得违规，需保证这14天一直持有主播安全吗绿码。</li>  
            <li>3、挂播一般建议第14天后开始，隔天挂播，后续可以天天挂播。</li>  
           </ul>  
           <br/>
         <p style="margin-top:20px"><strong>选品原则</strong></p>  
          <ul>  
            <li>1、新主播请默认选择【选品】-【智能选品】默认设置。</li>  
            <li>2、新主播请提前一天发布预告，以此类推，系统将自动预告上品。</li>  
            <li>3、新主播不建议删除回放，等后续挂播后可设置自动删除回放。</li>  
            <li>4、起号阶段多关注7天后的流量变化，不要过多关注成交，前期是养成阶段。</li>  
            
          </ul>  
        </div>  
         <div class="tutorial">
          <p><strong>其它说明</strong></p>  
          <ul>  
            <li>更多功能介绍和使用帮助：<a href="https://www.yuque.com/koudai666/nsxi4d/ha6glc" target="_blank">查看使用帮助</a></li>  
            <li>适用于正式主播的电脑直播：<a href="https://www.yuque.com/koudai666/nsxi4d/dcy7ug" target="_blank">下载&安装教程</a></li>  
            <li>适用于试用主播挂播：<a href="https://obsproject.com/" target="_blank">obs推流软件</a><span>|</span><a href="https://www.yuque.com/koudai666/nsxi4d/fognvg4fiz2385qm" target="_blank">录播回放教程</a></li>  
             <li >另有阿里官方合作业务：主播涨关注粉丝、直播间真实流量等数据业务。</li>  
            <!-- <li>head 新增快捷入口：<a href="https://hot.taobao.com/">热浪联盟</a><span>|</span><a>Mcn机构后台</a></li>   -->
            <p class="gg-right hoverable">
              <button class="btn-mcnget">咨询客服 weikoudai<img src="https://zbm666.com/image/weixin.png" class="hidden-img"></button>
            </p>
          </ul>  
        </div>  
      </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'HomeView',
  computed:{
    ...mapState({
      userInfo:state => state.userInfo.userInfo
    })
  },
  created(){
    
  },
  methods:{
    calculateSevenDay(date){
      return this.$calculateDays(date) > 7;
    },
  },

}
</script>
 