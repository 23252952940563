//过滤器
import Vue from "vue";

const globalFilters = [  
    {  
        name: 'FormatDate',  
        fn: function (value) {  
            return value.split(" ")[0]
        }
    },
    {  
        name: 'FormatDateTwo',  
        fn: function (value) {  
            return value.split(" ")[0].replace('-','.');
        }
    },
    {  
        name: 'calculateDaysDifference',  
        fn: function (value) {  
            // 将给定的时间字符串解析为Date对象  
            const givenDate = new Date(value);
            // 获取当前时间的Date对象  
            const now = new Date();  
            // 计算两个日期之间的毫秒差  
            const diff = givenDate - now;  
            // 将毫秒差转换为天数  
            // 注意：这里除以的是毫秒数/天（1000 * 60 * 60 * 24），并且使用Math.abs来确保结果是正数  
            const daysDiff = Math.abs(Math.ceil(diff / (1000 * 60 * 60 * 24)));
            return daysDiff; 
        }
    },
];

// 遍历数组并注册每个过滤器  
globalFilters.forEach(filter => {  
    Vue.filter(filter.name, filter.fn);
});

// Vue.filter("FormatDate",value=>{
//     return value.split(" ")[0]
// })